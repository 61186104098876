<template>
  <div>
    <el-table :data="requests"></el-table>
  </div>
</template>
<script>
export default {
  name: "RequestList",
  data() {
    return {
      requests: [],
    };
  },
};
</script>
<style scoped></style>
